"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyName_1 = require("@/common/colonies/ColonyName");
const ColonyRow_vue_1 = require("@/client/components/colonies/ColonyRow.vue");
const ColonyTradeRow_vue_1 = require("@/client/components/colonies/ColonyTradeRow.vue");
const ClientColonyManifest_1 = require("@/client/colonies/ClientColonyManifest");
const ColonyBenefit_1 = require("@/common/colonies/ColonyBenefit");
const Resource_1 = require("@/common/Resource");
const i18n_1 = require("@/client/directives/i18n");
exports.default = vue_1.default.extend({
    name: 'colony',
    props: {
        colony: {
            type: Object,
        },
    },
    components: {
        ColonyRow: ColonyRow_vue_1.default,
        ColonyTradeRow: ColonyTradeRow_vue_1.default,
    },
    computed: {
        cubeXPosition() {
            return this.colony.trackPosition * 56 + 27;
        },
        colonyXPositions() {
            return [0, 1, 2, 3].map((index) => index * 56 + 16);
        },
        colonyCubeOffset() {
            return 7;
        },
        cubeYPosition() {
            switch (this.colony.name) {
                case ColonyName_1.ColonyName.IAPETUS:
                case ColonyName_1.ColonyName.LEAVITT:
                    return 181;
                case ColonyName_1.ColonyName.VENUS:
                    return 186;
                case ColonyName_1.ColonyName.PALLAS:
                    return 168;
                case ColonyName_1.ColonyName.MERCURY:
                case ColonyName_1.ColonyName.HYGIEA:
                    return 144;
                case ColonyName_1.ColonyName.EUROPA:
                case ColonyName_1.ColonyName.MIRANDA:
                    return 166;
                case ColonyName_1.ColonyName.PLUTO:
                    return 165;
                case ColonyName_1.ColonyName.LUNA:
                    return 163;
                case ColonyName_1.ColonyName.DEIMOS:
                    return 188;
                default:
                    return 164;
            }
        },
        getColonyContentOffset() {
            switch (this.colony.name) {
                case ColonyName_1.ColonyName.PLUTO:
                case ColonyName_1.ColonyName.MIRANDA:
                    return -12;
                case ColonyName_1.ColonyName.DEIMOS:
                    return 3;
            }
            return 0;
        },
        metadata() {
            return (0, ClientColonyManifest_1.getColony)(this.colony.name);
        },
        colonyResourceClass() {
            const resource = this.metadata.cardResource;
            return resource?.toString()?.toLowerCase() ?? '';
        },
        backgroundClass() {
            return this.colony.name.replace(' ', '-') + '-background';
        },
        tooltip() {
            const descriptions = this.metadata.description.map(i18n_1.translateText);
            const titles = ['Build Colony bonus', 'Trade bonus', 'Colony bonus'].map(i18n_1.translateText);
            return `${titles[0]}: ${descriptions[0]}
${titles[1]}: ${descriptions[1]}
${titles[2]}: ${descriptions[2]}`;
        },
        ColonyName() {
            return ColonyName_1.ColonyName;
        },
        ColonyBenefit() {
            return ColonyBenefit_1.ColonyBenefit;
        },
        Resource() {
            return Resource_1.Resource;
        },
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const GameModule_1 = require("@/common/cards/GameModule");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardType_1 = require("@/common/cards/CardType");
function preludeCardNames(module) {
    return (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byModule)(module))
        .filter((0, ClientCardManifest_1.byType)(CardType_1.CardType.PRELUDE))
        .map(ClientCardManifest_1.toName);
}
exports.default = vue_1.default.extend({
    name: 'PreludesFilter',
    props: {
        promoCardsOption: {
            type: Boolean,
        },
        shilCardsOption: {
            type: Boolean,
        },
        communityCardsOption: {
            type: Boolean,
        },
        moonExpansion: {
            type: Boolean,
        },
        pathfindersExpansion: {
            type: Boolean,
        },
    },
    data() {
        const x = GameModule_1.GAME_MODULES.map((module) => [module, []]);
        const cardsByModule = Object.fromEntries(x);
        (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.PRELUDE)).forEach((card) => {
            cardsByModule[card.module].push(card.name);
        });
        GameModule_1.GAME_MODULES.forEach((module) => cardsByModule[module].sort());
        return {
            filterText: '',
            cardsByModule: cardsByModule,
            customPreludesList: false,
            selectedPreludes: [
                ...preludeCardNames('prelude'),
                ...this.promoCardsOption ? preludeCardNames('promo') : [],
                ...this.shilCardsOption ? preludeCardNames('shil') : [],
                ...this.communityCardsOption ? preludeCardNames('community') : [],
                ...this.moonExpansion ? preludeCardNames('moon') : [],
                ...this.pathfindersExpansion ? preludeCardNames('pathfinders') : [],
            ],
            GAME_MODULES: GameModule_1.GAME_MODULES,
        };
    },
    methods: {
        updatePreludes(cardNames) {
            this.selectedPreludes = [];
            for (const cardName of this.getItemsByGroup('All')) {
                if (cardNames.includes(cardName)) {
                    this.selectedPreludes.push(cardName);
                }
            }
        },
        getItemsByGroup(group) {
            if (group === 'All')
                return GameModule_1.GAME_MODULES.map((module) => this.cardsByModule[module]).flat();
            const corps = this.cardsByModule[group];
            if (corps === undefined) {
                console.log('module %s not found', group);
                return [];
            }
            else {
                return corps.slice();
            }
        },
        selectAll(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                if (this.selectedPreludes.includes(item) === false) {
                    this.selectedPreludes.push(item);
                }
            }
        },
        removeFromSelection(cardName) {
            const itemIdx = this.selectedPreludes.indexOf(cardName);
            if (itemIdx !== -1) {
                this.selectedPreludes.splice(itemIdx, 1);
            }
        },
        selectNone(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                this.removeFromSelection(item);
            }
        },
        invertSelection(group) {
            const items = this.getItemsByGroup(group);
            for (const idx in items) {
                if (this.selectedPreludes.includes(items[idx])) {
                    this.removeFromSelection(items[idx]);
                }
                else {
                    this.selectedPreludes.push(items[idx]);
                }
            }
        },
        watchSelect(module, enabled) {
            enabled ? this.selectAll(module) : this.selectNone(module);
        },
        expansions(prelude) {
            return (0, ClientCardManifest_1.getCard)(prelude)?.compatibility ?? [];
        },
        icon(module) {
            let suffix = module;
            if (module === 'colonies')
                suffix = 'colony';
            if (module === 'moon')
                suffix = 'themoon';
            return `create-game-expansion-icon expansion-icon-${suffix}`;
        },
        moduleName(module) {
            switch (module) {
                case 'promo': return 'Promo';
                case 'prelude': return 'Prelude';
                case 'community': return 'Community';
                case 'moon': return 'The Moon';
                case 'pathfinders': return 'Pathfinders';
            }
            return '';
        },
        include(name) {
            const normalized = this.filterText.toLocaleUpperCase();
            if (normalized.length === 0) {
                return true;
            }
            return name.toLocaleUpperCase().includes(normalized);
        },
    },
    watch: {
        selectedPreludes(value) {
            this.$emit('prelude-list-changed', value);
        },
        prelude(enabled) {
            this.watchSelect('prelude', enabled);
        },
        promoCardsOption(enabled) {
            this.watchSelect('promo', enabled);
        },
        shilCardsOption(enabled) {
            this.watchSelect('shil', enabled);
        },
        communityCardsOption(enabled) {
            this.watchSelect('community', enabled);
        },
        moonExpansion(enabled) {
            this.watchSelect('moon', enabled);
        },
        pathfindersExpansion(enabled) {
            this.watchSelect('pathfinders', enabled);
        },
    },
});
